class Api {
  constructor({baseUrl, headers}) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  _checkExecution(resolve) {
    if (resolve.ok) {
      return resolve.json();
    }
    return Promise.reject(`Ошибка: ${resolve.status}`);
  }

  async getUserIp(from) {
    const response = await fetch(`${this._baseUrl}/location/`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({from})
    });
    return this._checkExecution(response);
  }
}

const api = new Api({
  baseUrl: 'https://api.extra-develop.ru',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default api
